<template>
    <div class="home">
        <div class="banner">
            <img src="@/assets/images/banner.png" class="banner-img"/>
        </div>

        <div class="home-message">
            <img src="@/assets/images/home-img.png"/>

            <div class="message-info">
                <div class="info-title">About ApexMed Ventures</div>
                <div class="info-subtitle">ApexMed Ventures specializes in investing in companies at the forefront of technological innovation and healthcare. Our primary focus areas include:</div>
                <div class="info-text">
                    <div class="item">Technological Innovation: Including AI, IoT, software development, and more.</div>
                    <div class="item">Healthcare:Such as therapeutics (small molecules, protein therapeutics, antibodies), medical devices, and diagnostics.</div>
                </div>
                <div class="info-subtitle">After making an investment, the ApexMed Ventures team collaborates closely with our portfolio companies in several key areas:</div>
                <div class="info-text">
                    <div class="item">Research and Development Strategy: Assisting in the design and implementation of strategies for developing new technologies and medical solutions.</div>
                    <div class="item">Management Recruitment: Helping to identify and recruit top-tier management talent.</div>
                    <div class="item">Business Development Activities: Supporting initiatives to expand and grow the business.</div>
                    <div class="item">Follow-on Equity and Debt Financing: Providing additional funding through equity or debt.</div>
                    <div class="item">Achieving Liquidity through M&A or IPO: Guiding companies towards successful mergers, acquisitions, or initial public offerings to realize investment returns.</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    data: () => ({

    })
}
</script>

<style scoped lang="scss">
.home-message {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    width: 100%;
    padding: 90px 70px 90px 94px;

    background-color: #F1F2F7;

    > img {
        flex: none;
        width: 510px;
        height: 546px;
    }
}

.message-info {
    flex: auto;
    display: flex;
    flex-direction: column;

    width: 100%;
    padding: 50px 0px 0 50px;

    .info-title {
        font-size: 48px;
        font-weight: 300;
        letter-spacing: 1px;
        line-height: 31px;
        color: #003F70;
    }

    .info-subtitle {
        font-size: 21px;
        font-weight: 300;
        line-height: 31px;
        color: #1E1E1E;
        margin: 40px 0;
    }

    .info-text {
        position: relative;
        padding-left: 48px;

        .item {
            display: flex;
            position: relative;
            font-size: 21px;
            font-weight: 300;
            line-height: 31px;
            color: #1E1E1E;
            margin-bottom: 20px;

            &::after {
                content: "";
                position: absolute;
                top: 13px;
                left: -13px;
                width: 5px;
                height: 5px;
                border-radius: 50%;
                background-color: #1E1E1E;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}
</style>
