<template>
    <div class="header">
        <img class="logo" src="@/assets/images/logo.png"/>

        <div class="menu">
            <div :class="['item', activeIndex === 1 ? 'active' : '']" @click="open(0)">About</div>
            <div :class="['item', activeIndex === 2 ? 'active' : '']" @click="open(1)">Team</div>
            <div :class="['item', activeIndex === 3 ? 'active' : '']" @click="open(2)">Protfolio</div>
            <div :class="['item', activeIndex === 4 ? 'active' : '']" @click="open(3)">ESG</div>
            <div class="item border" @click="open(4)">Contact Us</div>
        </div>
    </div>
</template>

<script>
    export default {
        data: () => ({
            urlList: [
                {
                    index: 1,
                    path: "/home"
                },
                {
                    index: 2,
                    path: "/team"
                },
                {
                    index: 3,
                    path: "/protfolio"
                },
                {
                    index: 4,
                    path: "/esg"
                },
                {
                    index: 5,
                    path: "/contact"
                }
            ],
            activeIndex: 1
        }),
        watch: {
            $route(to, from) {
                this.urlList.forEach(item => {
                    if(to.path.indexOf(item.path) > -1) {
                        this.activeIndex = item.index
                    }
                })
            }
        },
        methods: {
            open(listIndex) {
                this.activeIndex = this.urlList[listIndex].index
                this.$router.push(this.urlList[listIndex].path)
            }
        }
    }
</script>

<style scoped lang="scss">
.header {
    position: fixed;
    top: 0;
    left: 50%;

    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 1440px;
    height: 160px;
    background-color: rgba(25, 63, 109, 0.5);
    padding: 55px 60px 40px 67px;
    transform: translate(-50%, 0);
    z-index: 100;

    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 24px;
        background-color: rgba(28, 69, 118, 1);
    }
}

.logo {
    width: 197px;
    height: 65px;
}

.menu {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 455px;
    height: 32px;

    > .item {
        display: flex;
        align-items: center;

        position: relative;

        font-size: 18px;
        font-weight: 300;
        color: #FFFFFF;
        line-height: 32px;

        &:hover {
            cursor: pointer;
            opacity: .7;
        }

        &.active::after {
            content: "";
            position: absolute;
            top: 100%;
            left: 50%;
            width: 24px;
            height: 2px;
            border-radius: 2px;
            background-color: #FFFFFF;
            transform: translate(-50%, 0);
        }
    }

    > .border {
        padding: 0 15px;
        border-radius: 5px;
        border: 1px solid #FFFFFF;
    }
}
</style>