import Vue from 'vue'
import VueRouter from 'vue-router'
import home from '../views/home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: "/home"
  },
  {
    path: '/home',
    name: 'home',
    component: home
  },
  {
    path: '/team',
    name: 'team',
    component: () => import('../views/team.vue')
  },
  {
    path: '/protfolio',
    name: 'protfolio',
    component: () => import('../views/protfolio.vue')
  },
  {
    path: '/protfolioA',
    name: 'protfolioA',
    component: () => import('../views/protfolio-item-1.vue')
  },
  {
    path: '/protfolioB',
    name: 'protfolioB',
    component: () => import('../views/protfolio-item-2.vue')
  },
  {
    path: '/protfolioC',
    name: 'protfolioC',
    component: () => import('../views/protfolio-item-3.vue')
  },
  {
    path: '/protfolioD',
    name: 'protfolioD',
    component: () => import('../views/protfolio-item-4.vue')
  },
  {
    path: '/esg',
    name: 'esg',
    component: () => import('../views/esg.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('../views/contact.vue')
  },
  {
    path: "*",
    meta: {
      isDisplay: true
    },
    component: () => import('../views/404.vue')
  }
]

const router = new VueRouter({
  mode: "history",
  routes
})

const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch(err => err)
}

export default router
