<template>
    <div class="footer-bg">
        <div class="f-logo">
            <img src="@/assets/images/logo.png"/>
        </div>
        <div class="f-text">
            <div class="text-top">
                <div class="item">About</div>
                <div class="item">Team</div>
                <div class="item">Protfolio</div>
                <div class="item">ESG</div>
                <div class="item">Contact Us</div>
            </div>
            <div class="text-email">Email: info@apexmedventures.com</div>
        </div>
    </div>
</template>

<script>
    export default {

    }
</script>

<style scoped lang="scss">
.footer-bg {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 256px;
    padding: 0 65px;
    background-color: #00457A;
}

.f-logo {
    display: flex;

    position: relative;

    > img {
        width: 197px;
        height: 65px;
    }
}

.f-text {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    width: 400px;

    .text-top {
        display: flex;
        align-items: center;
        justify-content: space-between;

        width: 100%;
        height: 40px;

        > .item {
            display: flex;
            align-items: center;
            justify-content: center;
            width: auto;
            font-size: 16px;
            font-weight: 400;
            line-height: 40px;
            color: #FFFFFF;
        }
    }

    .text-email {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        font-size: 16px;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 32px;
        margin-top: 12px;
    }
}
</style>